// Using a separate file for constants since Cypress has trouble pulling constants from other files.

export enum QUERY_PARAM_KEY { // TODO: rename to QueryParamKey.
  ACTIVE_VIEW = "activeView",
  /**
   * If this parameter exists and has a value (i.e. if the visitor clicked "Read more" on the teacher's short bio), we'll show the fullBio instead of the briefBio.
   */
  BIO = "bio",
  /**
   * When activeView === booked, the URL will also contain the BOOKED_LESSON_ID
   */
  BOOKED_LESSON_ID = "bookedLessonId",

  DATE_TIME = "datetime",

  INSTRUMENT = "i",

  /**
   * Shown when a user sees the activeView=booked page; either true or false depending on if we were able to successfully pay the invoice.
   */
  PAYMENT_SUCCESS = "paymentSuccess",
  /**
   * Key whose value is the 0-indexed index of the matching result record (not the page or slide index, which will be derived).
   */
  RESULT_INDEX = "i",

  SELECTED_TEACHER_OF_ID = "selectedTeacherOfId",

  TIME_ZONE = "tz",
}

export enum LearningHubQueryParam {
  ACTIVE_LEARNER_OF = "lo",
}

export enum ActiveViewEnum {
  /**
   * PAYMENT shows the payment details and "Add a child/student user" form
   */
  BOOKED = "booked",
  /**
   * For both of these views: DayPicker, TimePicker.
   */
  DATE_TIME = "datetime",
  /**
   * WSJ: This is just the default view. It may not be necessary any more.
   */
  INFO = "info",
  /**
   * PAYMENT shows the payment details and "Add a child/student user" form
   */
  PAYMENT = "payment",

  /**
   * If the user clicked the rating/reviews badge on the teacher's profile, this view is shown.
   */
  REVIEWS = "reviews",
}
