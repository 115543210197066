import { type User } from "@datadog/browser-core";
import { datadogLogs } from "@datadog/browser-logs";
import {
  type RumFetchResourceEventDomainContext,
  datadogRum,
} from "@datadog/browser-rum";

import { useContentTrackingContext } from "@components/TrackingAndAnalytics/ContentTrackingContextProvider";

import { NEXT_PUBLIC_VERCEL_ENV } from "../constants/config";
import {
  type TrackableEventContext,
  type TrackableEventNames,
} from "../constants/trackableEvents";

import { useLogTrackableEventMutation } from "@graphql";

type LoggedUserT = {
  email?: string;
  id: string;
  name: string;
  role?: string;
};

function getOperationName(
  context: RumFetchResourceEventDomainContext,
): undefined | string {
  const requestInitBody = context.requestInit?.body?.toString() || "";

  if (!requestInitBody) {
    return;
  }

  try {
    const parsedBody: unknown = JSON.parse(requestInitBody);
    if (
      parsedBody &&
      typeof parsedBody === "object" &&
      "operationName" in parsedBody &&
      typeof parsedBody.operationName === "string"
    ) {
      return parsedBody.operationName;
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error("Error parsing operation name", context);
  }

  return undefined;
}

class Logger {
  private static instance: Logger;

  private datadogEnabled: boolean;

  private debugEnabled: boolean;

  private constructor() {
    /* eslint-disable n/no-process-env */
    const clientToken = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
    const site = process.env.NEXT_PUBLIC_DATADOG_SITE;
    const service = process.env.NEXT_PUBLIC_DATADOG_SERVICE;
    const applicationId = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID;
    const commitMessage = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE;

    // If you want to use RUM in a preview environment, you need to add your git-branch-name here
    const previewBranchesToEnableDatadogLogging = ["main", "nextgen-ile"];
    this.datadogEnabled =
      NEXT_PUBLIC_VERCEL_ENV === "production" ||
      (NEXT_PUBLIC_VERCEL_ENV === "preview" &&
        previewBranchesToEnableDatadogLogging.includes(
          process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF as string,
        ));

    this.debugEnabled = process.env.NODE_ENV !== "production";
    /* eslint-enable n/no-process-env */

    const configError = !clientToken || !site || !service || !applicationId;
    if (!configError && this.datadogEnabled) {
      datadogLogs.init({
        clientToken,
        site,
        service,
        version: commitMessage,
        env: NEXT_PUBLIC_VERCEL_ENV,
        forwardConsoleLogs: "all",
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
      datadogRum.init({
        applicationId,
        clientToken,
        site,
        service,
        version: commitMessage,
        env: NEXT_PUBLIC_VERCEL_ENV,
        sampleRate: 100,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
        beforeSend: (event, context) => {
          if (
            event.type === "resource" &&
            event.resource.type === "fetch" &&
            "requestInit" in context
          ) {
            const operationName = getOperationName(context);
            const requestHeaders = context.requestInit?.headers;
            const requestId =
              (requestHeaders &&
                !Array.isArray(requestHeaders) &&
                !(requestHeaders instanceof Headers) &&
                requestHeaders["x-request-id"]) ||
              undefined;

            event.context = {
              ...event.context,
              responseHeaders: context.response?.headers,
              operationName,
              requestId,
            };
          }
        },
      });
      datadogRum.startSessionReplayRecording();
    } else if (configError && this.datadogEnabled) {
      console.error("Datadog configuration error");
    } else {
      // eslint-disable-next-line no-console
      console.info("Disabling Datadog in development");
    }
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }

    return Logger.instance;
  }

  // Shouldn't do anything in production
  public debug(message: string, context?: object) {
    if (this.debugEnabled) {
      // eslint-disable-next-line no-console
      console.debug(message, context);
    }
  }

  public info(message: string, context?: object) {
    // eslint-disable-next-line no-console
    console.info(message, context, datadogRum.getInternalContext()?.session_id);
    if (this.datadogEnabled) {
      datadogLogs.logger.info(message, context);
    }
  }

  public warn(message: string, context?: object) {
    console.warn(message, context);
    if (this.datadogEnabled) {
      datadogLogs.logger.warn(message, context);
    }
  }

  public error(message: string, context?: object) {
    console.error(message, context);
    if (this.datadogEnabled) {
      datadogLogs.logger.error(message, context);
    }
  }

  public updateUser(user: false | LoggedUserT) {
    if (!user) {
      datadogRum.clearUser();
      datadogLogs.clearUser();
      this.debug("Cleared logger user");
      return;
    }

    const loggedUser: User = {
      name: user.name,
      id: user.id,
      role: user.role,
      email: user.email,
    };

    if (this.datadogEnabled) {
      datadogRum.setUser(loggedUser);
      datadogLogs.setUser(loggedUser);
    }

    // this.debug("[logger] Updated user", loggedUser);
  }

  public trackEvent(eventName: string, context?: object) {
    if (this.datadogEnabled) {
      datadogLogs.logger.info(eventName, context);
    }

    this.debug(`[logger] Tracked event: ${eventName}`, context);
  }
}

export const useLogTrackableEvent = () => {
  const [logTrackableEvent] = useLogTrackableEventMutation();
  const contentTrackingContext = useContentTrackingContext();

  function executeLogTrackableEvent<EventName extends TrackableEventNames>(
    eventName: EventName,
    context: TrackableEventContext[EventName] extends object
      ? TrackableEventContext[EventName]
      : null,
  ) {
    Logger.getInstance().debug(
      `TrackableEvent: ${eventName}`,
      context ?? undefined,
    );

    const completeContext = {
      ...context,
      contentTrackingIds: contentTrackingContext?.allContentTrackingIds,
    };

    return logTrackableEvent({
      variables: {
        input: {
          eventName,
          context: JSON.stringify(completeContext),
        },
      },
    });
  }

  return executeLogTrackableEvent;
};

export const logger = Logger.getInstance();
