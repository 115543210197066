// Check logic in components/MaybeViewerProvider.tsx and components/AuthGatekeeper.tsx and _app.tsx to see rules for authorization and redirects.
import { MARKETING_SITE } from "../constants/config";
import {
  type RoleT,
  RoleTypeEnum,
  type StudentOrParentRoleT,
} from "../constants/role";
import { typecheckExhaustiveNeverCase } from "../util/helper";
import { SignupModeOptions } from "./joinHelpers";
import { Routes } from "./routesSimple";
import { replaceTrailingSquareBracketsChunk } from "./stringHelper";

export { BASE_URL, DOMAIN, LOCALHOST } from "./routesSimple";

export enum StripeRoute {
  ONBOARDING = "onboarding",
  RETURN = "return",
}

export const ABOUT = `${MARKETING_SITE}/about/`;
export const BLOG = `${MARKETING_SITE}/blog/`;
export const CONTACT_US = `${MARKETING_SITE}/contact-us/`;
export const HOME = `${MARKETING_SITE}/`;
export const HELP = `${MARKETING_SITE}/help/`;
export const INVITE_TEACHER = `${MARKETING_SITE}/refer`; // ! No trailing slash!

/**
 * General (for any role)
 */
export const INDEX = "/"; // aka ROOT or SLASH
export const ACCEPT_INVITATION = "/accept-invitation";
export const FORGOT_PASSWORD = "/forgot-password";

export const JOIN = "/join";
export const JOIN_AS_PARENT = JOIN + "/" + SignupModeOptions.PARENT;
export const JOIN_AS_LEARNER = JOIN + "/" + SignupModeOptions.LEARNER;
export const JOIN_AS_TEACHER = JOIN + "/" + SignupModeOptions.TEACHER;

const STUDIO = "/studio";
export const STUDIO_ILE = `${STUDIO}/`;
export const MUST_RESET_PASSWORD = "/must-reset-password";
export const PRIVACY_POLICY = `${MARKETING_SITE}/privacy-policy/`;
export const RESET_PASSWORD = "/reset-password";
export const SET_PASSWORD = "/set-password";
export const SETTINGS = "/settings";
export const SIGN_IN = "/sign-in";
export const SIGN_OUT = "/sign-out";
export const TERMS_OF_USE = `${MARKETING_SITE}/terms-of-use/`;
export const NOT_FOUND_PAGE = "/404";

export const SOCIAL_FACEBOOK = "https://www.facebook.com/forte_lessons";
export const SOCIAL_INSTAGRAM = "https://www.instagram.com/fortelessons/";
export const SOCIAL_TWITTER = "https://twitter.com/fortelessons";
export const SOCIAL_YOUTUBE =
  "https://www.youtube.com/channel/UCgwf2bYUEqPCzSp7jZMjyrQ";

export const TEACHER_PATH = "/teacher";
export const TEACHER_STUDIO = `${TEACHER_PATH}/studio`;
export const TEACHER_STUDENTS = `${TEACHER_PATH}/students`;
export const TEACHER_MATERIALS = `${TEACHER_PATH}/materials`;

export const TEACHER_PROFILE = `${TEACHER_PATH}/profile`;
export const TEACHER_PROFILE_BY_TEACHEROF_ID = `${Routes.TEACHER_PROFILE}/[teacherOfId]`;
export const TEACHER_STANDALONE_DASHBOARD = "/standalone/dashboard";
/**
 * @deprecated Use Routes enum instead
 */
export const TEACHER_SCHEDULE = Routes.TEACHER_SCHEDULE;
/**
 * @deprecated Use Routes enum instead
 */
export const TEACHER_BILLING = Routes.TEACHER_BILLING;
export const TEACHER_BILLING_ONBOARDING = `${Routes.TEACHER_BILLING}/${StripeRoute.ONBOARDING}`;
export const TEACHER_BILLING_RETURN = `${Routes.TEACHER_BILLING}/${StripeRoute.RETURN}`;

export const STUDENT = "/student";
/**
 * Also known as "Learning Hub".
 * Maybe we'll want to move this to `/`.
 * @deprecated Use Routes enum instead
 */
export const LEARNING_HUB = `${STUDENT}`;
/**
 * "Find a Teacher"
 */
export const STUDENT_PAYMENT = `${STUDENT}/payment`;
export const STUDENT_PAYMENT_RETURN = `${STUDENT_PAYMENT}/${StripeRoute.RETURN}`;
export const STUDENT_MY_FAMILY = `${STUDENT}/my-family`;

export const ANONYMOUS_BROWSE = `/browse`;

/**
 * @deprecated Use Routes enum instead
 */
export const STUDENT_SCHEDULE = `${STUDENT}/schedule`;

const STUDENT_RESERVE_BASE = `/reserve`;
export const STUDENT_RESERVE_SCHEDULE = `${STUDENT_RESERVE_BASE}/schedule`;
export const STUDENT_RESERVE_REGISTER = `${STUDENT_RESERVE_BASE}/register`;
export const STUDENT_RESERVE_SUMMARY = `${STUDENT_RESERVE_BASE}/summary`;
export const STUDENT_RESERVE_CHECKOUT = `${STUDENT_RESERVE_BASE}/checkout`;
export const STUDENT_RESERVE_BOOKED = `${STUDENT_RESERVE_BASE}/booked`;

export function getTeacherSchedulePath(
  teacherOfId: string,
  reservePath:
    | typeof STUDENT_RESERVE_SCHEDULE
    | typeof STUDENT_RESERVE_REGISTER
    | typeof STUDENT_RESERVE_SUMMARY
    | typeof STUDENT_RESERVE_CHECKOUT
    | typeof STUDENT_RESERVE_BOOKED,
) {
  return `${TEACHER_PROFILE}/${teacherOfId}${reservePath}`;
}

const ADMIN = "/admin";
export const ADMIN_STUDIO = `${ADMIN}/studio`;
export const ADMIN_STUDENTS = `${ADMIN}/students`;
export const ADMIN_USERS = `${ADMIN}/users`;
export const ADMIN_DAILY_DATA_LOG = `${ADMIN}/data-log`;
export const ADMIN_TWILIO_LOG = `${ADMIN}/twilio-log`;
export const ADMIN_CONNECT_TEST = `${ADMIN}/connect`;
export const ADMIN_PENDING_PROFILES = `${ADMIN}/profiles`;

export enum SurveyQuestionEnum {
  ageGroup = "ageGroup",
  aspirationLevel = "aspirationLevel",
  genres = "genres",
  musicTheoryLevel = "musicTheoryLevel",
  skillLevel = "skillLevel",
}

export enum SurveySnippetEnum {
  SNIPPET_1 = "snippet-one",
  SNIPPET_2 = "snippet-two",
  SNIPPET_3 = "snippet-three",
  SNIPPET_4 = "snippet-four",
}

export const SURVEY = "/explore/";
export const SURVEY_INSTRUMENT_SUBROUTE = "[instrumentId]";
export const SURVEY_INSTRUMENT = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}`;
export const SURVEY_LEARNER_NAME = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/learnerName`;
export const SURVEY_PARENT_EMAIL = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/parent-email`;
export const SURVEY_AGE_GROUP = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveyQuestionEnum.ageGroup}`;
export const SURVEY_ASPIRATION_LEVEL = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveyQuestionEnum.aspirationLevel}`;
export const SURVEY_SKILL_LEVEL = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveyQuestionEnum.skillLevel}`;
export const SURVEY_MUSIC_THEORY_LEVEL = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveyQuestionEnum.musicTheoryLevel}`;
export const SURVEY_GENRES = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveyQuestionEnum.genres}`;
export const SURVEY_SIGNUP_OR_LOGIN = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/signUpOrLogin`;

export const SURVEY_SNIPPET_1 = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveySnippetEnum.SNIPPET_1}`;
export const SURVEY_SNIPPET_2 = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveySnippetEnum.SNIPPET_2}`;
export const SURVEY_SNIPPET_3 = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveySnippetEnum.SNIPPET_3}`;
export const SURVEY_SNIPPET_4 = `${SURVEY}${SURVEY_INSTRUMENT_SUBROUTE}/${SurveySnippetEnum.SNIPPET_4}`;

export const getExploreRouteForInstrumentId = (instrumentId: string) => {
  return `${SURVEY}${instrumentId}`;
};

export const generateTeacherResultsPathForLearnerOfId = (learnerOfId: string) =>
  STUDENT + `/teachers/${learnerOfId}`;

export const generateTeacherResultsPathForAnonymousBrowsingMatchingRequestId = (
  matchingRequestId: string,
) => ANONYMOUS_BROWSE + "/" + matchingRequestId;

/**
 * Routes that the user is allowed to visit even when unauthenticated.
 * SIGN_IN is a weird exception -- when we do the login, we call to NextAuth which responds with
 * a set-cookie, which immediately makes us status:authenticated, but we're still on the /sign-in route.
 * Therefore SIGN_IN must be in the `optionalAuth` bucket, and it's in charge of redirecting itself
 * on its initial render. This same weirdness also applies to `JOIN`.
 */
export const mayBeUnauthenticatedPaths = [
  ACCEPT_INVITATION,
  MUST_RESET_PASSWORD, // A user visiting here wouldn’t have a `viewer` although technically *would* be authenticated.
  SIGN_OUT,
  SURVEY,
  STUDIO_ILE,
  TEACHER_PROFILE_BY_TEACHEROF_ID,
  STUDENT_RESERVE_SCHEDULE,
  STUDENT_RESERVE_SUMMARY,
  NOT_FOUND_PAGE,
  ANONYMOUS_BROWSE,
  SIGN_IN, // See comment above.
  JOIN, // See comment above.
];

export const roleAgnosticPaths = [MUST_RESET_PASSWORD, SETTINGS];

// Routes that the user can only visit if NOT authenticated.
export const mustBeUnauthenticatedPaths = [FORGOT_PASSWORD, RESET_PASSWORD];

/**
 * Be sure that you use MaybeViewer instead of AuthedViewer for any functions within these pages.
 * Paths in which you may not have a viewer.
 * TODO: Matt and Ryan want to improve this name.
 */
export const viewerOptionalPaths = [
  ...mustBeUnauthenticatedPaths,
  ...mayBeUnauthenticatedPaths,
];

/**
 * @param {string} routerPathName From router.pathname, which strips query parameters.
 * @param {string[]} paths Can include dynamic paths whose slugs include placeholders (such as `/teacher/profile/[teacherOfId]`).
 * @returns
 */
export function isPathInArray(
  routerPathName: string,
  paths: string[],
): boolean {
  return paths.some((path) => {
    return routerPathName.startsWith(
      replaceTrailingSquareBracketsChunk(path, ""),
    );
  });
}

export const roleCanViewRoute = (currentRole: RoleT, path: string): boolean => {
  if (
    isPathInArray(path, [...mayBeUnauthenticatedPaths, ...roleAgnosticPaths])
  ) {
    return true;
  }

  const allowedRootPathFragmentLookup = {
    [RoleTypeEnum.TEACHER]: [
      TEACHER_PATH,
      STUDIO,
      /**
       * Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
       */
      JOIN,
    ],
    [RoleTypeEnum.STUDENT_OR_PARENT]: [
      STUDENT,
      STUDIO,
      SET_PASSWORD,
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      JOIN, // Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
    ],
    [RoleTypeEnum.FORTE_ADMIN]: [
      ADMIN,
      STUDIO,
      TEACHER_PROFILE_BY_TEACHEROF_ID,
      JOIN, // Because of similar weirdness as explained above mayBeUnauthenticatedPaths.
    ],
  };

  const allowedAuthedRoutePrefixes =
    allowedRootPathFragmentLookup[currentRole.type];
  const result = isPathInArray(path, allowedAuthedRoutePrefixes);

  return result;
};

/**
 * This can be thought of as the default / "home" for the role.
 */
export function getRedirectPathForRole(
  role:
    | Exclude<RoleT, StudentOrParentRoleT>
    | Pick<
        StudentOrParentRoleT,
        "type" | "viewerNumTeachingRelationshipsAsLearner"
      >,
): string {
  let redirectToPath: string;

  if (role.type === RoleTypeEnum.STUDENT_OR_PARENT) {
    if (role.viewerNumTeachingRelationshipsAsLearner === 0) {
      redirectToPath = LEARNING_HUB;
    } else {
      redirectToPath = Routes.LEARNING_HUB;
    }
  } else if (role.type === RoleTypeEnum.TEACHER) {
    redirectToPath = TEACHER_STUDENTS;
  } else if (role.type === RoleTypeEnum.FORTE_ADMIN) {
    redirectToPath = ADMIN_STUDIO;
  } else {
    typecheckExhaustiveNeverCase(role);
    throw new Error("Invalid role.type");
  }

  // eslint-disable-next-line no-console
  console.debug(`Redirecting to ${redirectToPath}. Role: ${role.type}`);

  return redirectToPath;
}
