import { type PropsWithChildren, useEffect } from "react";
import { createPortal } from "react-dom";

import { useReducedMotion } from "@react-spring/web";
import { twMerge } from "tailwind-merge";
import { useScrollLock } from "usehooks-ts";

import { useKeyPress } from "@util/useKeypress";

export function DrawerModal({
  children,
  className = "",
  isDrawerOpen,
  onClose,
}: {
  className?: string;
  isDrawerOpen: boolean;
  onClose: () => void;
} & PropsWithChildren) {
  const prefersReducedMotion = useReducedMotion();

  const escapePressed = useKeyPress("Escape");

  useEffect(() => {
    if (escapePressed) {
      onClose();
    }
  }, [escapePressed, onClose]);

  return createPortal(
    <>
      {isDrawerOpen && <ScrollLockOverlay onClose={onClose} />}
      <div
        tabIndex={-1}
        className={twMerge(
          "overflow-y-auto fixed top-0 right-0 z-40 shadow-md h-full rounded-tl-md border-l border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 w-full max-w-6xl",
          !isDrawerOpen && "translate-x-full",
          !prefersReducedMotion && "transition-transform",
          className,
        )}
      >
        {children}
      </div>
    </>,
    document.body,
  );
}

function ScrollLockOverlay({ onClose }: { onClose: () => void }) {
  const prefersReducedMotion = useReducedMotion();
  useScrollLock({ lockTarget: "#root" });

  return (
    <div
      onClick={() => {
        onClose();
      }}
      className={twMerge(
        "bg-forteTransparentOverlay fixed inset-0 z-30",
        !prefersReducedMotion && "animate-fade-in",
      )}
    />
  );
}
