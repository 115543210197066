import { type ReactNode, useEffect } from "react";
// eslint-disable-next-line import/no-unassigned-import
import "react-phone-number-input/style.css";

import { ApolloProvider } from "@apollo/client";
import { Globals, useReducedMotion } from "@react-spring/web";
import { Flowbite } from "flowbite-react";
import { type NextPage } from "next";
import { type Session } from "next-auth";
import { SessionProvider, getSession } from "next-auth/react";
import NextApp, { type AppContext, type AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { customFlowbiteTheme } from "styles/CustomFlowbiteTheme";

import { AuthGatekeeper } from "@components/AuthGatekeeper";
import { AuthRefresher } from "@components/AuthRefresher";
import { AuthedViewerProvider } from "@components/AuthedViewerProvider";
import { BookmarkedTeacherProvider } from "@components/BookmarkedTeacherProvider";
import { AppChatDrawer } from "@components/Chat/AppChat/AppChatDrawer";
import { AppChatProvider } from "@components/Chat/AppChat/AppChatProvider";
import { ChatClientManagerProvider } from "@components/ChatClientManagerContext";
import { ConfirmationModalProvider } from "@components/ConfirmationModalProvider";
import { FeatureFlagProvider } from "@components/FeatureFlagContext";
import { MaybeViewerProvider } from "@components/MaybeViewerProvider";
import { OnboardingQueryParamCaptureProvider } from "@components/OnboardingQueryParamCaptureProvider";
import { Pendo } from "@components/Pendo";
import { TimeZoneUpdateModal } from "@components/TimezoneSelector";
import { ToastProvider } from "@components/ToastProvider";
import { ContentTrackingContextProvider } from "@components/TrackingAndAnalytics/ContentTrackingContextProvider";
import { isPathInArray, viewerOptionalPaths } from "@helpers/routes";
import { LayoutDataProvider } from "@layouts/LayoutDataProvider";

/* eslint-disable import/no-unassigned-import */
import "../styles/FullCalendarThemeing.css";
import "../styles/ReactPhoneNumberInput.css";
import "../styles/globals.scss";
import "../styles/webFonts.css";

/* eslint-enable import/no-unassigned-import */
import { getApolloClient } from "@graphql/apollo";

/**
 * FortePage type
 *
 * This extends the existing NextPage type to include our extra props for layout, title, etc.
 */
export type FortePage<P = FixMe> = NextPage<P> & {
  /**
   * The component in which to wrap this page's contents
   */
  pageLayout?: (page: ReactNode) => ReactNode;

  /**
   * The title to show in the browser for this page
   */
  pageTitle?: string;
};

/**
 * App
 *
 * This is the top-level Next.js component that initializes everything else
 * See https://nextjs.org/docs/advanced-features/custom-app
 */
const App: NextPage<AppProps & { Component: FortePage }> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const generatePageTitle = () => {
    if (Component.pageTitle) {
      return `${Component.pageTitle} | Forte`;
    } else {
      return "Forte";
    }
  };

  useEffect(() => {
    const htmlElement = document.documentElement;

    htmlElement.classList.add("dark");
  }, []);

  /**
   * applyLayout
   *
   * Wraps the page in its `.pageLayout` if it exists
   *
   * Pages can define a `.pageLayout` property on themselves if they are
   * intended to be rendered within a particular layout.
   *
   * If `.pageLayout` is defined, render the page within it;
   * Otherwise, just render the page as-is.
   *
   * https://github.com/vercel/next.js/blob/v12.2.2/docs/basic-features/layouts.md
   */
  const applyLayout = Component.pageLayout || ((page) => page);

  const router = useRouter();

  const isViewerOptionalPath = isPathInArray(
    router.pathname,
    viewerOptionalPaths,
  );

  const prefersReducedMotion = useReducedMotion();
  Globals.assign({ skipAnimation: Boolean(prefersReducedMotion) });

  const sessionData = session ? session : {};

  return (
    <Flowbite theme={{ theme: customFlowbiteTheme }}>
      <Head>
        <title>{generatePageTitle()}</title>
        {/* Disable input zooming on iOS: https://stackoverflow.com/a/46254706 */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="manifest" href="/site.webmanifest" />
      </Head>
      {/* If pageProps.session isn't provided, MyApp is being rendered client-side. In this case
          we make sure to not provide any session at all so that the session cache maintained
          by the SessionProvider would be reused. */}

      <SessionProvider {...sessionData}>
        <AuthGatekeeper>
          <ApolloProvider client={getApolloClient()}>
            <ChatClientManagerProvider>
              <BookmarkedTeacherProvider>
                {/* We should consider methods for refactoring/removing LayoutDataProvider */}
                <LayoutDataProvider>
                  <AuthRefresher />
                  <ToastProvider>
                    <MaybeViewerProvider>
                      <OnboardingQueryParamCaptureProvider>
                        <ContentTrackingContextProvider>
                          {isViewerOptionalPath ? (
                            applyLayout(<Component {...pageProps} />)
                          ) : (
                            <AuthedViewerProvider>
                              <AppChatProvider>
                                <AppChatDrawer />
                                <TimeZoneUpdateModal />
                                <Pendo />
                                <FeatureFlagProvider>
                                  <ConfirmationModalProvider>
                                    {applyLayout(<Component {...pageProps} />)}
                                  </ConfirmationModalProvider>
                                </FeatureFlagProvider>
                              </AppChatProvider>
                            </AuthedViewerProvider>
                          )}
                        </ContentTrackingContextProvider>
                      </OnboardingQueryParamCaptureProvider>
                    </MaybeViewerProvider>
                  </ToastProvider>
                </LayoutDataProvider>
              </BookmarkedTeacherProvider>
            </ChatClientManagerProvider>
          </ApolloProvider>
        </AuthGatekeeper>
      </SessionProvider>
    </Flowbite>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
(App as any).getInitialProps = async (appContext: AppContext) => {
  /* eslint-enable @typescript-eslint/no-explicit-any */

  let session: Session | null | undefined;

  // getSession works both server-side and client-side but we want to avoid any calls to /api/auth/session
  // on page load, so we only call it server-side.
  // https://github.com/nextauthjs/next-auth/issues/345#issuecomment-1012178372
  if (typeof window === "undefined") {
    session = await getSession(appContext.ctx);
  }

  const appProps = await NextApp.getInitialProps(appContext);

  if (session === undefined) {
    return {
      ...appProps,
    };
  }

  return {
    ...appProps,
    pageProps: {
      session,
    },
  };
};

export default App;
