import React, {
  type PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import ReactGA from "react-ga4";

type ContentTrackingContextType = {
  addNewTrackingId: (trackingId: string) => void;
  allContentTrackingIds: Array<string>;
};

export const contentTrackingQueryParam = "contentTracking";

export enum KnownContentTrackingIds {
  BOOK_YOURSELF_A_TRIAL_LESSON = "promobanner-book-your-self-a-trial-lesson",
  KEEP_UP_THE_MOMENTUM = "promobanner-keep-up-the-momentum",
}

const ContentTrackingContext = createContext<ContentTrackingContextType | null>(
  null,
);

export function useContentTrackingContext() {
  return useContext(ContentTrackingContext);
}

export function ContentTrackingContextProvider({
  children,
}: PropsWithChildren) {
  const [contentTrackingIds, setContentTrackingIds] = useState<Array<string>>(
    [],
  );

  const handleAddNewTrackingId = (trackingId: string) => {
    setContentTrackingIds((prev) => [...prev, trackingId]);

    ReactGA.gtag("event", "content_display", {
      event_category: "Content",
      event_label: trackingId,
    });
  };

  const providerValue: ContentTrackingContextType = useMemo(() => {
    return {
      allContentTrackingIds: contentTrackingIds ?? [],
      addNewTrackingId: handleAddNewTrackingId,
    };
  }, [contentTrackingIds]);

  return (
    <ContentTrackingContext.Provider value={providerValue}>
      {children}
    </ContentTrackingContext.Provider>
  );
}
